<template>
  <div class="homePage">
    <!-- echarts -->
    <div class="home_drawTotal" v-if="goodsStatisticsButton==1">
      <div class="home_echarts clearfix">
        <!-- 路线销售总额 柱状图-->
        <div class="echarts_box clearfix" style="text-align: right">
          <el-date-picker v-model="totalSalesTime" type="date" @change="getDailySale" placeholder="选择日期" value-format="yyyy-MM-dd">
          </el-date-picker>
          <div class="echarts_item" id="totalSalesEchart"></div>
        </div>
        <!-- 路线周统计 双柱状 -->
        <div class="echarts_box clearfix" style="text-align: right">
          <div class="monthOrWeek">
            <el-switch style="display: block" v-model="monthWeek" @change="getWeekOrMonthSale" active-color="#13ce66" inactive-color="#ff4949" active-text="按月" inactive-text="按周">
            </el-switch>
          </div>
          <div class="echarts_item" id="weekSaleEcharts"></div>
        </div>

      </div>
      <!-- 商品销售排行榜 -->
      <div class="goodsSaleBox">
        <div class="topBox">
          <h6>商品销售排行榜</h6>
          <el-switch style="display: block" v-model="goodsSaleListData.monthWeek" @change="getGoodsSaleListMonth" active-color="#13ce66" inactive-color="#ff4949" active-text="按月" inactive-text="按周">
          </el-switch>
          <el-select v-model="goodsSaleListData.monthStr" placeholder="选择月" @change="changeMonthStr(1,$event)" style="width:100px;margin-right:20px">
            <el-option v-for="item in monthAll" :key="item.id" :label="item.monthstr" :value="item.monthstr">
            </el-option>
          </el-select>
          <el-select v-show="!goodsSaleListData.monthWeek" v-model="goodsSaleListData.weekId" placeholder="选择周" @change="changeWay(1)" style="width:280px;margin-right:20px">
            <el-option v-for="item in goodsWeekList" :key="item.id" :label="'第'+item.sort+'周：'+item.startTime+'-'+item.endTime" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="goodsSaleListData.wayId" filterable clearable placeholder="选择路线" @change="changeWay(1)">
            <el-option v-for="item in driverWayList" :key="item.value" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="goodsSaleList " style="display:flex">
          <div v-for="(item,index) in goodsSaleList" :key="index">
            <div v-if="item.saleAmountStatisticsGoodsMallList.length">
              <div class="big tlt">
                {{item.way}}<el-button type="primary" size="mini" style="margin-left:30px" @click="exportEvent(item.id,item.way,1)">导出</el-button>
              </div>
              <div class="big tlt">
                <span>排名</span>
                <span>产品名称</span>
                <span>销售额</span>
              </div>
              <div class="table">
                <div v-if="item.saleAmountStatisticsGoodsMallList.length">
                  <div class="big" v-for="(it,ind) in item.saleAmountStatisticsGoodsMallList" :key="ind">
                    <span>{{ind+1}}</span>
                    <span>{{it.goodsName}}</span>
                    <span>{{it.saleAmount}} 元</span>
                  </div>
                </div>
                <div v-else class="big noData">
                  暂无数据
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- 团长销售排行列表 -->
      <div class="goodsSaleBox">
        <div class="topBox">
          <h6>团长销售排行列表</h6>
          <el-switch style="display: block" v-model="userSaleListData.monthWeek" @change="getUserSaleListMonth" active-color="#13ce66" inactive-color="#ff4949" active-text="按月" inactive-text="按周">
          </el-switch>
          <el-select v-model="userSaleListData.monthStr" placeholder="选择月" @change="changeMonthStr(2,$event)" style="width:100px;margin-right:20px">
            <el-option v-for="item in monthAll" :key="item.id" :label="item.monthstr" :value="item.monthstr">
            </el-option>
          </el-select>
          <el-select v-show="!userSaleListData.monthWeek" v-model="userSaleListData.weekId" placeholder="选择周" @change="changeWay(2)" style="width:280px;margin-right:20px">
            <el-option v-for="item in userWeekList" :key="item.id" :label="'第'+item.sort+'周：'+item.startTime+'-'+item.endTime" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="userSaleListData.wayId" clearable filterable placeholder="选择路线" @change="changeWay(2)">
            <el-option v-for="item in driverWayList" :key="item.value" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="goodsSaleList " style="display:flex">
          <div v-for="(item,index) in userSaleList" :key="index">
            <div v-if="item.saleAmountStatisticsUserList.length">
              <div class="big tlt">
                {{item.way}} <el-button type="primary" size="mini" style="margin-left:30px" @click="exportEvent(item.id,item.way,2)">导出</el-button>
              </div>
              <div class="big tlt">
                <span>排名</span>
                <span>微信昵称</span>
                <span>销售额</span>
                <span>上{{!userSaleListData.monthWeek?'周':'月'}}排名</span>
                <span>利润</span>
              </div>
              <div class="table">
                <div v-if="item.saleAmountStatisticsUserList.length">
                  <div class="big" v-for="(it,ind) in item.saleAmountStatisticsUserList" :key="ind" @click="changeGrossDetail(it,item.id)">
                    <span>{{ind+1}}</span>
                    <span>{{it.wechat}}</span>
                    <span>{{it.saleAmount}} 元</span>
                    <span>{{it.lastRanking||"暂无"}}</span>
                    <span>{{it.gross}} 元</span>
                  </div>
                </div>

                <div v-else class="big noData">
                  暂无数据
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <!-- 分月查询每日销售额统计 -->
      <div class="goodsSaleBox">
        <div class="topBox">
          <h6>每日销售额统计</h6>
          <el-select v-model="monthArr" multiple clearable placeholder="选择月份" @change="changeMonth">
            <el-option v-for="item in monthList" :key="item.value" :label="item.monthstr" :value="item.monthstr">
            </el-option>
          </el-select>
          <!-- <el-button @click="handleDownLoad">导出</el-button> -->
          <el-button type="primary" style="margin-left:20px" @click="upload">导出</el-button>
        </div>
        <el-table ref="multipleTable" :data="selectAmountSaleList.dataList" height="800" :span-method="selectAmountSaleListMethod" :row-class-name="selectAmountSaleListClass" :cell-class-name="selectAmountSaleListCellClass" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="每日销售额统计表" align="center">
            <el-table-column label="月" prop="monthStr" align="center">
            </el-table-column>
            <el-table-column label="日期" prop="dayStr" align="center">
            </el-table-column>
            <el-table-column v-for="(item,index) in selectAmountSaleList.wayList" :key="index" :label="item.way" align="center">
              <template slot-scope="scope">
                <p v-for="(it,ind) in scope.row.wayList" :key="ind">
                  <span v-if="item.id==it.wayId">{{ it.sale }} <span v-if="scope.row.dayStr!='周环比'">元</span></span>
                </p>
              </template>
            </el-table-column>
            <el-table-column label="合计" prop="daySaleMount" align="center">
              <template slot-scope="scope">
                {{ scope.row.daySaleMount }} <span v-if="scope.row.dayStr!='周环比'">元</span>
              </template>
            </el-table-column>
            <el-table-column prop="monthSaleMount" align="center">
              <template slot-scope="scope">
                {{ scope.row.monthSaleMount }} 元
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <!-- 无订单团长列表 -->
      <div class="goodsSaleBox">
        <div class="topBox">
          <h6>无订单团长列表</h6>
          <el-date-picker @change="changePicker" v-model="time" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <el-button size="mini" type="primary" @click="exportUserData" style="margin-left:30px">导出</el-button>
        </div>
        <el-table ref="multipleTable" :data="selectUserList" height="600" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="username" label="序号">
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column prop="username" label="用户名"> </el-table-column>
          <el-table-column prop="wechat" label="微信昵称" width="200"> </el-table-column>
          <el-table-column prop="towns" label="所属乡镇" min-width="120">
          </el-table-column>
          <el-table-column prop="shopName" label="门店名称">
          </el-table-column>
          <el-table-column prop="address" label="地址" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" min-width="110">
          </el-table-column>
          <el-table-column prop="level" label="等级">
            <template slot-scope="scope">{{
              scope.row.level == 0 ? "普通用户" : "团长"
              }}</template>
          </el-table-column>
          <el-table-column prop="statusFlag" label="账号状态">
            <template slot-scope="scope">
              <p v-if="scope.row.status == 1" style="color: #409eff">
                已激活
              </p>
              <p v-else-if="scope.row.status == 0" style="color: #f56c6c">
                已禁用
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="auditStatus" label="审核状态" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <p v-if="scope.row.auditStatus == 0" style="color: #409eff">
                待审核
              </p>
              <p v-else-if="scope.row.auditStatus == 1" style="color: #67c23a">
                通过
              </p>
              <p v-else-if="scope.row.auditStatus == 2" style="color: #f56c6c">
                拒绝
              </p>
              <p v-else-if="scope.row.auditStatus == null" style="color: #f56c6c">
                授权登录未提交审核
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="created" label="创建时间" min-width="140">
          </el-table-column>
          <el-table-column prop="way" label="路线" width="100"> </el-table-column>
          <el-table-column align="center" width="180" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleLock(0, scope.row.id)" :disabled="scope.row.status == 0">禁用</el-button>
              <el-button size="mini" type="danger" @click="delUser(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="利润明细" :visible.sync="grossDetailShow" width="90%">
      <el-form :model="grossDetail" :inline="true" ref="grossDetail" label-width="100px" class="demo-ruleForm text-center">
        <el-form-item label="微信昵称：">
          {{grossDetail.wechat}}
        </el-form-item>
        <el-form-item label="销售额：">
          {{grossDetail.saleAmount}}
        </el-form-item>
        <el-form-item :label="'上'+(!userSaleListData.grossDetail?'周':'月')+'排名:'">
          {{grossDetail.lastRanking||"暂无"}}
        </el-form-item>
        <el-form-item label="利润：">
          {{grossDetail.gross}}
        </el-form-item>
        <el-form-item label="时间：">
          {{grossDetail.monthWeek?'本月':('第'+grossDetail.week.sort+'周：'+grossDetail.week.startTime+'-'+grossDetail.week.endTime)}}
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="grossDetail.list" height="600" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="price" label="原价">
          <template slot-scope="scope">
            {{scope.row.price}} 元
          </template>
        </el-table-column>
        <el-table-column prop="salePrice" label="供货价">
          <template slot-scope="scope">
            {{scope.row.salePrice}} 元
          </template>
        </el-table-column>
        <el-table-column prop="costAmount" label="订单销售额">
          <template slot-scope="scope">
            {{scope.row.costAmount}} 元
          </template>
        </el-table-column>
        <el-table-column prop="saleAmount" label="原价销售额">
          <template slot-scope="scope">
            {{scope.row.saleAmount}} 元
          </template>
        </el-table-column>
        <el-table-column prop="gross" label="利润">
          <template slot-scope="scope">
            {{scope.row.gross}} 元
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import XLSXStyle from "xlsx-style";
import * as echarts from "echarts";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "data-backups",
  data() {
    return {
      grossDetail: {
        week: {}
      },
      grossDetailShow: false,
      totalSalesTime: "",
      driverWayList: [],
      monthWeek: false,
      goodsSaleList: [],
      //商品销售排行榜
      goodsSaleListData: {
        wayId: "",
        monthWeek: false,
        weekId: "",
        monthStr: ""
      },
      time: [],
      selectUserList: [],
      userSaleList: [],
      //团长销售排行榜
      userSaleListData: {
        wayId: "",
        monthWeek: false,
        weekId: "",
        monthStr: ""
      },
      //查询每日销售额统计
      selectAmountSaleData: {
        monthStr: "",
      },
      monthArr: [],
      selectAmountSaleList: {},
      monthList: [],
      goodsWeekList: [],
      userWeekList: [],
      goodsStatisticsButton: "",
      monthAll: []
    };
  },
  created() {
    this.getMonthList()
    this.getMonthAll()
    // 获取用户adcode
    this.adcode = sessionStorage.getItem("adcode");
    this.userArea = CodeToText[this.adcode];
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_statistics") {
        this.goodsStatisticsButton = "1";
      }
    });
  },
  mounted() {
    this.getTime()
    this.getDriverWayList()
    this.getWeekOrMonthSale(false)

  },
  methods: {
    //导出无订单用户
    exportUserData() {
      let _this = this;
      let data = JSON.parse(JSON.stringify({
        startTime: this.time[0],
        endTime: this.time[1]
      }))
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleAmountStatisticsGoods/selectUserListExport", {
          params: data,
          responseType: 'blob'
        })
        .then((res) => {
          var eleLink = document.createElement("a");
          eleLink.download = _this.time[0] + "——" + _this.time[1] + "无订单团长.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          // 然后移除
          document.body.removeChild(eleLink);
        })
    },
    //删除用户
    delUser(id) {
      var _this = this;
      _this
        .$confirm("此操作将永久删除该客户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var _this = this;
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/user/delete", {
              params: {
                ids: id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.changePicker(_this.time);
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //客户禁用
    handleLock(e, id) {
      let _this = this;
      let api = "";
      if (e) {
        api = "/user/userActivate";
      } else {
        api = "/user/userLock";
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + api, {
          params: {
            id,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.changePicker(_this.time);
            _this.$message({
              message: "操作成功！",
              type: "success",
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //团长销售排行 导出excel flag 1商品 2团长
    exportEvent(wayId, way, flag) {
      let data = { wayId }
      let fileName = ""
      let url = ''
      if (flag == 1) {
        if (this.goodsSaleListData.monthWeek) { //月
          data.type = 2
          fileName = way + '-' + this.goodsSaleListData.monthStr
        } else {
          data.type = 1
          data.weekId = this.goodsSaleListData.weekId
          var obj = this.goodsWeekList.find((item) => {
            return item.id == this.goodsSaleListData.weekId
          })
          fileName = way + '-' + this.goodsSaleListData.monthStr + '-第' + obj.sort + '周：' + obj.startTime + '至' + obj.endTime
        }
        data.monthStr = this.goodsSaleListData.monthStr
        url = '/saleAmountStatisticsGoods/goodsSaleListExport'
      } else {
        if (this.userSaleListData.monthWeek) { //月
          data.type = 2
          fileName = way + '-' + this.userSaleListData.monthStr
        } else {
          data.type = 1
          data.weekId = this.userSaleListData.weekId
          var obj = this.userWeekList.find((item) => {
            return item.id == this.userSaleListData.weekId
          })
          fileName = way + '-' + this.userSaleListData.monthStr + '-第' + obj.sort + '周：' + obj.startTime + '至' + obj.endTime
        }
        data.monthStr = this.userSaleListData.monthStr
        url = '/saleAmountStatisticsGoods/userSaleListExport'
      }
      let _this = this;
      this.$axios.get(_this.$axios.defaults.basePath + url, {
        params: data,
        responseType: 'blob'
      }).then(function (res) {
        console.log(res)
        var eleLink = document.createElement("a");
        eleLink.download = fileName + ".csv";
        eleLink.style.display = "none";

        var blob = new Blob([res.data]);
        eleLink.href = URL.createObjectURL(blob);
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        _this.$message({
          message: "导出成功",
          type: "success",
        });
        // 然后移除
        document.body.removeChild(eleLink);
      })
    },
    //查看利润详情
    changeGrossDetail(item, wayId) {
      let data = {}
      data.userId = item.userId
      data.wayId = wayId
      this.grossDetail = item
      this.grossDetail.monthWeek = this.userSaleListData.monthWeek
      if (this.userSaleListData.monthWeek) { //月
        let arr = this.getLastDay().split('/')
        data.startTime = arr[0]
        data.endTime = arr[1]
      } else {//周
        var obj = this.userWeekList.find((item) => {
          return item.id == this.userSaleListData.weekId
        })
        this.grossDetail.week = obj
        data.startTime = obj.startTime
        data.endTime = obj.endTime
      }
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleAmountStatisticsGoods/getGrossDetail", {
          params: data
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.grossDetail.list = res.data.data
            _this.grossDetailShow = true
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //获取当月最后一天的日期
    getLastDay() {
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      month = month < 10 ? '0' + month : month; //月份补 0
      let str = [year, month, '01'].join("-") + '/' + [year, month, lastDate].join("-")
      return str
    },
    // 下载excel
    upload() {
      let arr = JSON.parse(JSON.stringify(this.selectAmountSaleList.dataList))
      let wayList = JSON.parse(JSON.stringify(this.selectAmountSaleList.wayList))
      if (arr.length > 0) {
        for (let i in arr) {
          if (arr[i].wayList.length) {
            for (let j in wayList) {
              for (let k in arr[i].wayList) {
                if (wayList[j].id == arr[i].wayList[k].wayId) {
                  arr[i][wayList[j].way] = arr[i].wayList[k].sale
                  break;
                } else {
                  arr[i][wayList[j].way] = ""
                }
              }
            }
          } else {
            for (let j in wayList) {
              arr[i][wayList[j].way] = ""
            }
          }
        }
        // 表头设置
        const aoa = [["每日销售额统计表"]];
        let twoHeader = ["月", "日期"]
        for (let i in wayList) {
          twoHeader.push(wayList[i].way)
        }
        twoHeader.push("合计")
        twoHeader.push("")
        aoa.push(twoHeader);
        let dataFilterVal = [
          "monthStr",
          "dayStr",
        ]
        for (let i in wayList) {
          dataFilterVal.push(wayList[i].way)
        }
        dataFilterVal.push("daySaleMount")
        dataFilterVal.push("monthSaleMount")
        const data = this.formatJson(dataFilterVal, arr);
        for (let i in data) {
          aoa.push(data[i])
        }
        const sheet = this.xlsxAddStyle(aoa, twoHeader);
        // return
        this.openDownloadDialog(this.sheet2blob(sheet), this.monthArr.join(",") + `销售统计表.xlsx`);
      } else {
        this.$message.warning({
          message: "没有数据无法导出",
          duration: 1000,
        });
      }
    },
    /**
     * 通用的打开下载对话框方法，没有测试过具体兼容性
     * @param url 下载地址，也可以是一个blob对象，必选
     * @param saveName 保存文件名，可选
     */
    openDownloadDialog(url, saveName) {
      var urlA;
      if (typeof url === "object" && url instanceof Blob) {
        urlA = URL.createObjectURL(url); // 创建blob地址
      }
      const aLink = document.createElement("a");
      aLink.href = urlA;
      // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
      aLink.download = saveName || "";
      let event;
      if (window.MouseEvent) event = new MouseEvent("click");
      else {
        event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
      }
      aLink.dispatchEvent(event);
    },
    // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
    sheet2blob(sheet, sheetName) {
      var sheetNameS = sheetName || "sheet1";
      var workbook = {
        SheetNames: [sheetNameS],
        Sheets: {},
      };
      workbook.Sheets[sheetNameS] = sheet;
      // 生成excel的配置项
      var wopts = {
        bookType: "xlsx", // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: "binary",
      };
      var wbout = XLSXStyle.write(workbook, wopts);
      // XLSXStyle.write(wb, { bookType: bookType, bookSST: false, type: 'binary' });
      var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
      // 字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      return blob;
    },

    // 表格样式的设置
    xlsxAddStyle(xlsx, twoHeader) {
      const sheet = XLSX.utils.aoa_to_sheet(xlsx);
      const mergeArr = []; // 合并的单元格
      const rowH = []; // 表格每列高度
      // 单元格外侧有框线
      const borderAll = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      // 单元格外侧无框线
      const noBorder = {
        top: { style: "" },
        bottom: { style: "" },
        left: { style: "" },
        right: { style: "" },
      };
      let indexs = []
      let indexs2 = []//环比index
      for (const key in sheet) {
        if (Object.hasOwnProperty.call(sheet, key)) {
          const element = sheet[key];

          if (typeof element === "object") {
            const index = Number(key.slice(1)) - 1;
            rowH[index] = { hpx: 24 };
            element.s = {
              alignment: {
                horizontal: "center", // 所有单元格右对齐
                vertical: "center", // 所有单元格垂直居中
              },
              font: {
                name: "宋体",
                sz: 12,
                italic: false,
                underline: false,
              },
              border: borderAll,
              fill: {
                fgColor: { rgb: "FFFFFFFF" },
              },
            };
            // 标题的样式
            if (index === 0) {
              element.s.font.bold = true;
              element.s.fill.fgColor = { rgb: "FFC000" };
            }
            if (element.v == "周合计" || element.v == "周环比") {
              indexs.push(index)
            }
            if (element.v == "周环比") {
              indexs2.push(index)
            }
          }
        }
      }
      for (const key in sheet) {
        if (Object.hasOwnProperty.call(sheet, key)) {
          const element = sheet[key];
          if (typeof element === "object") {
            const index = Number(key.slice(1)) - 1;
            for (let i in indexs) {
              if (index == indexs[i]) {
                element.s.fill.fgColor = { rgb: "FFC000" };
              }
            }
            for (let i in indexs2) {
              if (index == indexs2[i]) {
                element.s.font.color = { rgb: "FF0000" };
              }
            }
          }
        }
      }
      let abc = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '']
      //合并第一列
      mergeArr.push("A1:" + abc[twoHeader.length - 1] + "1");
      let num = 3
      for (let i in this.selectAmountSaleList.monthList) {
        let a = num + (this.selectAmountSaleList.monthList[i].dataList.length - 1)
        let item = "A" + num + ":A" + a //以月为单位 合并当前的单元格 第一列
        let item2 = abc[twoHeader.length - 1] + num + ":" + abc[twoHeader.length - 1] + a //以月为单位 合并当前的单元格 最后一列
        num = a + 1
        mergeArr.push(item)
        mergeArr.push(item2)
      }
      let mergeArr2 = []
      mergeArr.forEach(item => {
        mergeArr2.push(XLSX.utils.decode_range(item))
      })
      let cols = [{ wpx: 100 }, { wpx: 140 }]
      for (let i in this.selectAmountSaleList.wayList) {
        cols.push({ wpx: 120 })
      }
      cols.push({ wpx: 120 })
      cols.push({ wpx: 120 })
      // 表头的样式设置
      sheet["A1"].s.alignment.horizontal = "center";
      sheet["A1"].s.font.underline = true;
      sheet["A1"].s.font.sz = 18;
      sheet["A1"].s.border = noBorder;
      // 单元格的列宽
      sheet["!cols"] = cols;
      sheet["!rows"] = rowH;
      sheet["!merges"] = mergeArr2;
      return sheet;
    },
    //通过属性名获取值。
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    //获取月份列表
    getMonthAll() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/weekItem/getMonthAll")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsSaleListData.monthStr = res.data.data[0].monthstr
            _this.userSaleListData.monthStr = res.data.data[0].monthstr
            _this.monthAll = res.data.data
            _this.getWeekList(0, _this.monthAll[0].monthstr)
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //获取本月的四周 type (type==0本月  type==1全部)   flag 1是商品排行 2是团长排行
    getWeekList(flag, monthStr) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/weekItem/getWeekList", {
          params: {
            monthStr
          }
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            if (flag == 1) { //商品排行
              _this.goodsWeekList = res.data.data
              _this.goodsSaleListData.weekId = res.data.data[0].id
              _this.getGoodsSaleListMonth(_this.goodsSaleListData.monthWeek)
            } else if (flag == 2) {//团长排行
              _this.userWeekList = res.data.data
              _this.userSaleListData.weekId = res.data.data[0].id
              _this.getUserSaleListMonth(_this.userSaleListData.monthWeek)
            } else {//全部
              _this.goodsWeekList = res.data.data
              _this.userWeekList = res.data.data
              _this.goodsSaleListData.weekId = res.data.data[0].id
              _this.userSaleListData.weekId = res.data.data[0].id
              _this.getGoodsSaleListMonth(false)
              _this.getUserSaleListMonth(false)
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    //选择月份 flag 1是商品排行 2是团长排行
    changeMonthStr(flag, e) {
      this.getWeekList(flag, e)
    },
    //选择路线 flag 1是商品排行 2是团长排行
    changeWay(flag) {
      if (flag == 1) {
        this.getGoodsSaleListMonth(this.goodsSaleListData.monthWeek)
      } else if (flag == 2) {
        this.getUserSaleListMonth(this.userSaleListData.monthWeek)
      }
    },
    //获取团长销售排行 1周  2月
    getUserSaleListMonth(flag) {
      let e = !flag ? 1 : 2
      let url = e == 1 ? "/saleAmountStatisticsGoods/userSaleListWeek" : "/saleAmountStatisticsGoods/userSaleListMonth"
      let obj = {
        wayId: this.userSaleListData.wayId,
        monthStr: this.userSaleListData.monthStr
      }
      if (e == 1) {
        obj.weekId = this.userSaleListData.weekId
      }
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + url, {
          params: obj
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.userSaleList = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //根据时间区间查询没有订单的用户
    changePicker(e) {
      this.time = e
      let a = JSON.parse(JSON.stringify(e))
      let obj = {
        startTime: a[0],
        endTime: a[1]
      }
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleAmountStatisticsGoods/selectUserList", {
          params: obj
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.selectUserList = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //获取商品销售排行榜 1周  2月
    getGoodsSaleListMonth(flag) {
      let e = !flag ? 1 : 2
      let url = e == 1 ? "/saleAmountStatisticsGoods/goodsSaleListWeek" : "/saleAmountStatisticsGoods/goodsSaleListMonth"
      let obj = {
        wayId: this.goodsSaleListData.wayId,
        monthStr: this.goodsSaleListData.monthStr
      }
      if (e == 1) {
        obj.weekId = this.goodsSaleListData.weekId
      }
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + url, {
          params: obj
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsSaleList = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //获取路线周销售额 1周 2月
    getWeekOrMonthSale(flag) {
      let e = !flag ? 1 : 2
      let url = e == 1 ? "/saleAmountStatisticsGoods/weekSale" : "/saleAmountStatisticsGoods/monthSale"
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + url)
        .then((res) => {
          if (res.data.errcode == 0) {
            if (e == 1) {
              res.data.data.lastMap = res.data.data.lastWeekMap
              res.data.data.thisMap = res.data.data.thisWeekMap
            } else {
              res.data.data.lastMap = res.data.data.lastMonthMap
              res.data.data.thisMap = res.data.data.thisMonthMap
            }
            _this.weekSaleEcharts(res.data.data, e)
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //路线周或月销售额- 双柱状图
    weekSaleEcharts(data, e) {
      let _this = this;
      // 产品销售总额
      let weekSaleEcharts = echarts.init(
        document.getElementById("weekSaleEcharts")
      );
      let option = {
        title: {
          text: e == 1 ? "路线周销售额(元)" : "路线月销售额(元)",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          show: true,
          textStyle: {
            align: "left",
          },
          //   formatter: "路线：{b0}<br />销售总额：{c0}",
        },
        grid: {
          top: "20%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: data.wayArray,
          axisLabel: {
            color: "#333",
            rotate: 45
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        series: [
          {
            type: "bar",
            name: data.lastMap.name,
            smooth: true,
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#6666ff" },
                { offset: 1, color: "#8080ff" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#6666ff" },
                  { offset: 1, color: "#8080ff" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: data.lastMap.data,
          },
          {
            type: "bar",
            name: data.thisMap.name,
            smooth: true,
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: data.thisMap.data,
          },
        ],
      };
      weekSaleEcharts.setOption(option, true);
      //   this.myEcharts_line1 = totalSalesEchart;
    },

    //获取销售总额
    getDailySale() {
      let _this = this;
      let obj = {
        day: this.totalSalesTime
      }
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleAmountStatisticsGoods/dailySale", {
          params: obj
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.totalSalesEchart(res.data.data)
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //路线销售总额- 柱状图
    totalSalesEchart(data) {
      let _this = this;
      // 产品销售总额
      let totalSalesEchart = echarts.init(
        document.getElementById("totalSalesEchart")
      );
      let option = {
        title: {
          text: "路线销售总额(元)",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          show: true,
          textStyle: {
            align: "left",
          },
          formatter: "路线：{b0}<br />销售总额：{c0}",
        },
        grid: {
          top: "20%",
          bottom: "15%",
        },
        xAxis: {
          data: data.wayArray,
          axisLabel: {
            color: "#333",
            rotate: 45
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        series: [
          {
            type: "bar",
            smooth: true,
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: data.amountArray,
          },
        ],
      };
      totalSalesEchart.setOption(option, true);
      //   this.myEcharts_line1 = totalSalesEchart;
    },
    //获取路线列表
    getDriverWayList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driverWay")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.driverWayList = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //获取当前时间
    getTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1);
      let day = String(date.getDate());
      let hour = String(date.getHours());
      let minutes = String(date.getMinutes());
      let seconds = String(date.getSeconds())
      month = month.length === 2 ? month : 0 + month;
      day = day.length === 2 ? day : 0 + day;
      hour = hour.length === 2 ? hour : 0 + hour;
      minutes = minutes.length === 2 ? minutes : 0 + minutes;
      seconds = seconds.length === 2 ? seconds : 0 + seconds;
      let startTime = `${year}-${month}-01 00:00:00`;
      let endTime = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
      this.time = [startTime, endTime]
      this.totalSalesTime = `${year}-${month}-${day}`
      this.monthArr.push(`${year}-${month}`)
      this.getDailySale()
      this.changePicker(this.time)
      this.getSelectAmountSaleList()
    },
    //获取月份列表
    getMonthList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/weekItem/getMonthList")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.monthList = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //选择月份
    changeMonth() {
      this.getSelectAmountSaleList()
    },
    //查询每日销售额统计 修改表格颜色 单元格
    selectAmountSaleListCellClass({ row, rowIndex, column, columnIndex }) {
      if (row.dayStr == "周环比") {
        for (let i in row.wayList) {
          if ((Number(i) + 2) == columnIndex) {
            if (row.wayList[i].sale.indexOf("-") == -1) {
              return "fontColor"
            }
          }

        }
        if ((Number(row.wayList.length) + 2) == columnIndex) {
          if (row.daySaleMount.indexOf("-") == -1) {
            return "fontColor"
          }
        }
      }
    },
    //查询每日销售额统计 修改表格颜色 行
    selectAmountSaleListClass({ row, rowIndex, column, columnIndex }) {
      if (row.dayStr == "周合计") {
        return "rowClass"
      } if (row.dayStr == "周环比") {
        return "rowClass2"
      } else {
        return ""
      }
    },
    //查询每日销售额统计 表格合并
    selectAmountSaleListMethod({ row, column, rowIndex, columnIndex }) {
      let num = 0
      for (let i in this.selectAmountSaleList.monthList) {
        num = this.selectAmountSaleList.monthList[i].dataList.length
        break;
      }
      let cIndex = this.selectAmountSaleList.wayList.length + 3
      if (columnIndex === 0 || columnIndex === cIndex) {
        if (rowIndex % num === 0) {
          return {
            rowspan: num,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    //查询每日销售额统计
    getSelectAmountSaleList() {
      let _this = this;
      this.selectAmountSaleData.monthStr = this.monthArr.join(",")
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleAmountStatisticsGoods/selectAmountSaleList", {
          params: this.selectAmountSaleData
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            let data = res.data.data
            let dataList = []
            for (let i in data.monthList) {
              for (let j in data.monthList[i].dataList) {
                data.monthList[i].dataList[j].monthSaleMount = data.monthList[i].monthSaleMount
                data.monthList[i].dataList[j].monthStr = data.monthList[i].monthStr
                dataList.push(data.monthList[i].dataList[j])
              }
            }
            data.dataList = dataList
            // res.data.data.wayList.unshift({ way: "日期", id: -1 })
            _this.selectAmountSaleList = data
            // console.log(_this.selectAmountSaleList.dataList, "_this.selectAmountSaleList.dataList")
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.goodsSaleBox >>> .el-table .fontColor {
  color: green !important;
}
.table {
  max-height: 700px;
  overflow-y: scroll;
  white-space: nowrap;
  /* overflow-y: hidden; */
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.table::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.goodsSaleBox >>> .el-table .rowClass,
.goodsSaleBox >>> .el-table .rowClass2 {
  background: rgba(255, 192, 0, 1) !important;
}
/* .goodsSaleBox >>> .el-table .rowClass td,
.goodsSaleBox >>> .el-table .rowClass2 td {
  border-color: rgba(255, 192, 0, 1) !important;
} */
.goodsSaleBox >>> .el-table .rowClass2 {
  color: red;
}
.goodsSaleBox {
  padding: 30px 0 50px 0;
  border-top: 1px solid #e5e5e5;
}
.goodsSaleBox .topBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.goodsSaleBox h6 {
  font-size: 22px;
  margin-right: 30px;
}
.goodsSaleBox .topBox .el-switch {
  margin-right: 30px;
}
.goodsSaleBox .goodsSaleList {
  width: 100%;
  overflow: auto;
  /* height: 600px; */
}
.goodsSaleBox .goodsSaleList .big {
  text-align: center;
  min-width: 500px;
  border-bottom: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
  padding: 10px 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goodsSaleBox .goodsSaleList .tlt {
  background: #f5f7fa;
  color: #909399 !important;
  font-size: 14px;
  font-weight: 600;
}
.goodsSaleBox .goodsSaleList .big span {
  /* display: inline-block; */
  width: 10%;
  text-align: center;
  padding: 10px 5px;
  box-sizing: border-box;
  color: #606266;
  /* display:flex; */
  white-space: normal;
  cursor: pointer;
}
.goodsSaleBox .goodsSaleList .big span:nth-of-type(2) {
  width: 40%;
}
.goodsSaleBox .goodsSaleList .big span:nth-of-type(3) {
  width: 20%;
}
.goodsSaleBox .goodsSaleList .big span:nth-of-type(4) {
  width: 15%;
}
.goodsSaleBox .goodsSaleList .big span:nth-of-type(5) {
  width: 15%;
}
.homePage {
  margin-top: 56px;
  padding: 20px;
  box-sizing: border-box;
}
.home_banner {
  width: 100%;
  height: 336px;
  padding: 60px 70px 40px 70px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: url("../../assets/img/home_banner.png") no-repeat;
  background-size: 100% 100%;
}
.home_banner .home_title {
  color: #fff;
  font-size: 16px;
}
.home_title p:first-child {
  font-size: 30px;
  margin-bottom: 10px;
}
.home_total {
  margin-top: 0;
}
.home_total_title {
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  margin-top: 15px;
}
.home_total ul li {
  float: left;
  min-width: 176px;
  padding: 25px;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  list-style: none;
  margin-right: 15px;
  background-color: rgba(243, 246, 253, 0.2);
}
.home_total ul li p:first-child + p {
  font-size: 30px;
  margin-top: 10px;
}
.home_drawTotal {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}
.home_drawTotal .total_title {
  font-size: 16px;
  margin-bottom: 10px;
}
.home_drawTotal .total_title .total_change span {
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
}
.home_drawTotal .total_title .total_change span.active {
  color: #246eff;
}
.home_echarts {
  width: 100%;
}
.home_echarts > div {
  width: 50%;
  height: 600px;
  float: left;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div {
  border-top: 0;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div + div {
  border-right: 1px solid #e5e5e5;
}
.echarts_box {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.echarts_box .monthOrWeek {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
}
.echarts_box >>> .el-date-editor {
  width: 380px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
}
.goodsSelect {
  position: absolute;
  top: 10px;
  right: 400px;
  z-index: 1000;
}
.noData {
  font-size: 16px;
  line-height: 400px;
  border-bottom: none !important;
}
@media screen and (max-width: 1700px) {
  .goodsSelect {
    left: 24% !important;
  }
}
.echarts_box .echarts_item {
  width: 100%;
  height: 550px;
}

.weclone {
  color: #333;
  text-align: center;
  font-size: 50px;
  padding-top: 300px;
}
.pageMain .item {
  width: 100%;
  height: 180px;
  position: relative;
  cursor: pointer;
}
.pageMain .item .item_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: auto;
  height: 180px;
}
.pageMain .item .icon_zhuan {
  position: inherit;
  top: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  -webkit-animation: changeright 6s linear infinite;
}
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.pageMain .item .item_main {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}
.pageMain .item .item_main img {
  display: block;
  /*padding-left: 15px;*/
  margin: 0 auto 10px auto;
}
.home_sort {
  width: 100%;
  margin-top: 20px;
}
.table_box {
  width: 100%;
  height: 500px;
  float: left;
  padding: 20px 0;
  margin-left: 1.4%;
  box-sizing: border-box;
  /* box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}
.table_box:first-child {
  margin-left: 0;
}
.table_box .sort_title {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}

.el-table {
  border: 1px solid #e3e3e3;
}

::v-deep .el-table thead {
  background: #e7efff;
}

/* ::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
} */

::v-deep .el-bg-gray,
::v-deep .el-table__row:hover {
  background: #f1f2f7 !important;
}
.nums {
  font-size: 20px;
  margin-left: 50px;
}
.nums span {
  font-size: 24px;
  font-weight: 600;
}
</style>
