<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="活动名称">
          <el-input
            v-model="searchForm.activityName"
            placeholder="请输入活动名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布状态">
          <el-select v-model="searchForm.status" placeholder="请选择发布状态">
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动类型">
          <el-select v-model="searchForm.type" placeholder="请选择活动类型">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动仓库">
          <el-select
            v-model="searchForm.warehouseId"
            placeholder="请选择活动仓库"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">活动列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button
            size="mini"
            type="primary"
            @click="handleAdd()"
            v-if="addButton == '1'"
            class="el-icon-plus"
          >
            添加</el-button
          >
          <el-button
            size="mini"
            type="danger"
            :disabled="ids.length == 0"
            @click="handleDel"
            v-if="delButton == '1'"
            class="el-icon-delete"
            >删除</el-button
          >
        </div>
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="(val) => handleSelectionChange(val, 'ids')"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="activityName"
              label="活动名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="type" label="活动类型" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.type == '1'">{{
                  typeList[scope.row.type - 1].name
                }}</el-tag>
                <el-tag v-else-if="scope.row.type == '2'" type="success">{{
                  typeList[scope.row.type - 1].name
                }}</el-tag>
                <el-tag v-else-if="scope.row.type == '3'" type="info">{{
                  typeList[scope.row.type - 1].name
                }}</el-tag>
                <el-tag v-else-if="scope.row.type == '4'" type="warning">{{
                  typeList[scope.row.type - 1].name
                }}</el-tag>
                <el-tag v-else-if="scope.row.type == '5'" type="danger">{{
                  typeList[scope.row.type - 1].name
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="warehouse"
              label="仓库"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="status"
              label="发布状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == '0'" type="warning"
                  >待发布</el-tag
                >
                <el-tag v-else-if="scope.row.status == '1'" type="success"
                  >已发布</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="startTime"
              label="开始时间"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="endTime"
              label="结束时间"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="created"
              label="创建时间"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column align="center" width="280" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleDetails(scope.row)"
                  v-if="searchButton == '1'"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)"
                  :disabled="scope.row.status == '1'"
                  v-if="updateButton == '1'"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  :type="scope.row.status == '0' ? 'primary' : 'danger'"
                  @click="handleUp(scope.row)"
                  v-if="toIssueButton == '1'"
                  >{{ scope.row.status == "0" ? "发布" : "禁用" }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-form
        v-if="dialogFormVisible"
        :model="addEditForm"
        ref="addEditForm"
        :rules="addEditRules"
        label-width="120px"
      >
        <div class="titleBox clearfix">
          <p class="float_lf">活动信息</p>
        </div>
        <el-form-item label="活动名称" prop="activityName">
          <el-input
            v-model="addEditForm.activityName"
            placeholder="请输入活动名称"
            autocomplete="off"
            style="width: 400px"
            maxlength="50"
            clearable
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动简称" prop="activityShortName">
          <el-input
            v-model="addEditForm.activityShortName"
            placeholder="请输入活动简称"
            autocomplete="off"
            style="width: 400px"
            maxlength="50"
            clearable
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="生效时间" prop="time">
          <el-date-picker
            v-model="addEditForm.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            :disabled="detailsShow"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动仓库" prop="warehouseId">
          <el-select
            v-model="addEditForm.warehouseId"
            placeholder="请选择活动仓库"
            :disabled="detailsShow || dialogTitle == '编辑'"
            @change="checkExist"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="detailsShow"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动状态" prop="status">
          <el-radio
            v-model="addEditForm.status"
            label="0"
            :disabled="detailsShow"
            >待发布</el-radio
          >
          <el-radio
            v-model="addEditForm.status"
            label="1"
            :disabled="detailsShow"
            >发布</el-radio
          >
        </el-form-item>
        <el-form-item label="活动规则" prop="describe">
          <el-input
            type="textarea"
            :rows="4"
            style="width: 600px"
            placeholder="请输入活动规则"
            v-model="addEditForm.describe"
            :disabled="detailsShow"
          >
          </el-input>
        </el-form-item>
        <div class="titleBox clearfix">
          <p class="float_lf">优惠设置</p>
        </div>
        <el-form-item label="优惠方式" prop="type">
          <el-radio-group
            v-model="addEditForm.type"
            :disabled="detailsShow"
            @change="onActivityTypeChange"
          >
            <el-radio label="0">多级优惠</el-radio>
            <el-radio label="5">倍数满减</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="titleBox clearfix">
          <p class="float_lf">优惠方式</p>
        </div>
        <el-table
          :data="addEditForm.activityRuleList"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="level" label="层级" width="180">
          </el-table-column>
          <el-table-column prop="threshold" label="优惠门槛" width="400">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'activityRuleList[' + scope.$index + '].threshold'"
              >
                <span v-if="addEditForm.type == 5">每满</span>
                <span v-else>满</span>
                <div class="inline type-mar">
                  <el-input
                    :placeholder="
                      '请输入优惠门槛' +
                      (addEditForm.unit == 1 ? '件数' : '金额')
                    "
                    v-model="
                      addEditForm.activityRuleList[scope.$index].threshold
                    "
                    class="input-with-select"
                    :disabled="detailsShow"
                  >
                    <el-button v-if="addEditForm.unit == 2" slot="prepend"
                      >￥</el-button
                    >
                    <el-select
                      v-model="addEditForm.unit"
                      slot="append"
                      placeholder="请选择"
                      style="width: 70px"
                      :disabled="detailsShow || addEditForm.type == 5"
                    >
                      <el-option label="件" value="1"></el-option>
                      <el-option label="元" value="2"></el-option>
                    </el-select>
                  </el-input>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="优惠方式" width="300">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'activityRuleList[' + scope.$index + '].discounts'"
              >
                <el-radio-group
                  v-model="addEditForm.activityRuleList[scope.$index].type"
                  @change="onActivityRuleTypeChange"
                >
                  <el-radio class="type-radio" label="1" :disabled="detailsShow"
                    >减现金
                    <el-input-number
                      v-if="
                        addEditForm.activityRuleList[scope.$index].type == '1'
                      "
                      v-model="
                        addEditForm.activityRuleList[scope.$index].discounts
                      "
                      :precision="2"
                      :step="1"
                      :min="0"
                      class="type-mar type-width"
                      size="mini"
                      placeholder="请输入金额"
                      :disabled="detailsShow"
                    ></el-input-number>
                    <div
                      v-if="
                        addEditForm.type == 5 &&
                        addEditForm.activityRuleList[scope.$index].type == '1'
                      "
                      class="type-up-limit"
                    >
                      <div class="titleBox clearfix">
                        <p class="float_lf">设置优惠上限</p>
                      </div>
                      <el-radio-group
                        v-model="
                          addEditForm.activityRuleList[scope.$index].upLimitType
                        "
                      >
                        <el-radio class="type-radio block" label="1"
                          >无上限</el-radio
                        >
                        <el-radio class="type-radio block" label="2"
                          >最多可减<el-input-number
                            v-model="
                              addEditForm.activityRuleList[scope.$index].upLimit
                            "
                            :precision="2"
                            :step="1"
                            :min="1"
                            class="type-mar type-width"
                            size="mini"
                            placeholder="请输入金额"
                            :disabled="detailsShow"
                          ></el-input-number
                          >元</el-radio
                        >
                      </el-radio-group>
                    </div>
                  </el-radio>
                  <el-radio
                    class="type-radio"
                    label="2"
                    :disabled="detailsShow || addEditForm.type == 5"
                    >打<el-input-number
                      v-if="
                        addEditForm.activityRuleList[scope.$index].type == '2'
                      "
                      v-model="
                        addEditForm.activityRuleList[scope.$index].discounts
                      "
                      :precision="2"
                      :step="1"
                      :min="0"
                      :max="10"
                      class="type-mar type-width"
                      size="mini"
                      placeholder="请输入折扣"
                      :disabled="detailsShow"
                    ></el-input-number
                    >折</el-radio
                  >
                  <el-radio
                    class="type-radio"
                    label="3"
                    :disabled="detailsShow || addEditForm.type == 5"
                    >免费送
                    <div
                      v-if="
                        addEditForm.activityRuleList[scope.$index].type == '3'
                      "
                      class="inline"
                    >
                      <el-input-number
                        v-model="
                          addEditForm.activityRuleList[scope.$index].discounts
                        "
                        :precision="0"
                        :step="1"
                        :min="1"
                        class="type-mar type-width-small"
                        size="mini"
                        placeholder="请输入件数"
                        :disabled="detailsShow"
                      ></el-input-number>
                      件
                    </div></el-radio
                  >
                  <el-radio
                    class="type-radio"
                    label="4"
                    :disabled="detailsShow || addEditForm.type == 5"
                    >送赠品</el-radio
                  >
                  <!-- <el-radio
                    class="type-radio"
                    label="5"
                    :disabled="addEditForm.type == 5"
                    >加钱换购<el-button
                      v-if="
                        addEditForm.activityRuleList[scope.$index].type == '5'
                      "
                      size="mini"
                      class="type-mar"
                      >添加换购</el-button
                    ></el-radio
                  > -->
                </el-radio-group>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="!detailsShow"
                size="mini"
                type="danger"
                @click="deleteActivityRuleList(scope.row)"
                :disabled="detailsShow || !scope.$index"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <el-button v-if="addActivityRuleListShow" @click="addActivityRuleList"
            >新增一级优惠（最多可设置五个层级）</el-button
          >
        </div>
        <active-goods-select
          :dialogTitle="dialogTitle"
          :detailsShow="detailsShow"
          :detail="addEditForm"
          @addActivityGoods="onAddActivityGoods"
          @addActivityGoodsGift="onAddActivityGoodsGift"
        ></active-goods-select>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')"
          >保存活动</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
    <el-drawer title="选择赠品" ref="drawer" :visible.sync="drawer" size="60%">
      <div style="padding: 32px">
        <el-table
          ref="activityGoods"
          :data="activityGoodsList"
          stripe
          style="width: 100%"
          @selection-change="(val) => handleSelectionChange(val, 'discounts')"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="standardImg" label="产品规格图片">
            <template slot-scope="scope">
              <div class="demo-image__preview" v-if="scope.row.standardImg">
                <el-image
                  style="width: 60px"
                  :src="scope.row.standardImg"
                  :preview-src-list="scope.row.standardImg.split(',')"
                >
                </el-image>
              </div>
              <img
                v-else
                src="../../assets/img/no_img.png"
                style="width: 60px"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="产品名称"
            min-width="140"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="describe"
            label="产品描述"
            width="130px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="standard" label="产品规格"></el-table-column>
          <el-table-column prop="num" label="库存"></el-table-column>
          <el-table-column prop="purchasing" label="进货价"></el-table-column>
          <el-table-column prop="price" label="原价"></el-table-column>
          <el-table-column
            prop="salePrice"
            label="乡兴供货价"
          ></el-table-column>
          <el-table-column prop="isTopCarriage" label="状态" min-width="80">
            <template slot-scope="scope">
              <p v-if="scope.row.isTopCarriage == 0" style="color: red">
                未上架
              </p>
              <p
                v-else-if="scope.row.isTopCarriage == 1"
                style="color: #67c23a"
              >
                已上架
              </p>
              <p v-else style="color: red">已下架</p>
            </template>
          </el-table-column>
        </el-table>
        <div class="demo-drawer__footer">
          <el-button @click="closeDrawer">取 消</el-button>
          <el-button type="primary" @click="toggleDrawer">选择完成</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import activeGoodsSelect from "@/components/active_goods_select";

export default {
  name: "activity",
  components: { activeGoodsSelect },
  data() {
    return {
      drawer: false,
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      searchButton: "",
      toIssueButton: "",
      searchForm: {
        activityName: "",
        status: "",
        type: "",
        warehouseId: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      defaultActivityRuleList: {
        level: "1",
        threshold: "",
        type: "1",
        discounts: "",
        unit: "1",
        upLimitType: "1",
        upLimit: "1",
      },
      defaultAddEditForm: {
        activityName: "",
        time: [],
        type: "0",
        warehouseId: "",
        status: "0",
        activityRuleList: [],
        activityGoodsFormList: [],
        describe: "",
        unit: "1",
      },
      addEditForm: {
        activityRuleList: [],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      typeList: [
        {
          id: "1",
          name: "立减",
        },
        {
          id: "2",
          name: "满折",
        },
        {
          id: "3",
          name: "满送",
        },
        {
          id: "4",
          name: "满赠",
        },
        {
          id: "5",
          name: "倍数",
        },
      ],
      statusList: [
        { id: "0", name: "待发布" },
        { id: "1", name: "发布" },
      ],
      warehouseList: [],
      activityGoodsList: [],
      discounts: [],
      discountsIndex: 0,
      //当前活动ID
      activeId:'',
    };
  },
  computed: {
    addEditRules() {
      let data = {
        activityName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        activityShortName: [
          { required: true, message: "请输入活动简称", trigger: "blur" },
        ],
        time: [{ required: true, message: "请选择生效时间", trigger: "blur" }],
        warehouseId: [
          { required: true, message: "请选择活动仓库", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择活动状态", trigger: "change" },
        ],
        describe: [
          { required: true, message: "请输入活动规则", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择优惠方式", trigger: "change" },
        ],
      };

      if (this.addEditForm && this.addEditForm.activityRuleList)
        this.addEditForm.activityRuleList.forEach((standard, index) => {
          data = Object.assign({}, data, {
            ["activityRuleList[" + index + "].threshold"]: [
              {
                trigger: "blur",
                validator: (rule, value, callback) =>
                  this.checkThreshold(rule, value, callback, index),
              },
            ],
            ["activityRuleList[" + index + "].discounts"]: [
              {
                trigger: "blur",
                validator: (rule, value, callback) =>
                  this.checkDiscounts(rule, value, callback, index),
              },
            ],
          });
        });

      return data;
    },
    addActivityRuleListShow() {
      const result = this.addEditForm.activityRuleList.some(
        (rule) => rule.type == 4
      );
      return !this.detailsShow && this.addEditForm.type != 5 && !result;
    },
  },
  created() {
    this.listEvent();
    this.getWarehouseList();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "activity_edit") {
        this.updateButton = "1";
      } else if (item.authority == "activity_add") {
        this.addButton = "1";
      } else if (item.authority == "activity_delete") {
        this.delButton = "1";
      } else if (item.authority == "activity_query") {
        this.searchButton = "1";
      } else if (item.authority == "activity_to_issue") {
        this.toIssueButton = "1";
      }
    });
  },
  methods: {
    ...mapMutations(["changeMenu"]),
    checkThreshold(rule, value, callback, index) {
      const unit = this.addEditForm.unit;
      if (!value) {
        return callback(
          new Error(`请输入优惠门槛${unit == 1 ? "件数" : "金额"}`)
        );
      }
      if (isNaN(value)) {
        return callback(new Error("请输入数字值"));
      }
      if (unit == 1 && value % 1 != 0) {
        return callback(new Error("请输入整数字值"));
      }
      if (index) {
        const prevThreshold =
          +this.addEditForm.activityRuleList[index - 1].threshold;
        if (+value <= (prevThreshold ? prevThreshold : 0))
          return callback(new Error("请输入大于比上一个层级的数字值"));
      }
      return callback();
    },
    checkDiscounts(rule, value, callback, index) {
      const { unit, type: formType } = this.addEditForm;
      const { type, threshold, upLimitType, upLimit } =
        this.addEditForm.activityRuleList[index];
      if (!value) {
        if (type == 1) return callback(new Error("请输入减现金数"));
        else if (type == 2) return callback(new Error("请输入打折折扣"));
        else if (type == 3) return callback(new Error("请输入件数"));
        else if (type == 4) return callback();
      }
      if (formType == "5") {
        if (upLimitType == 2 && !upLimit)
          return callback(new Error("请输入减现金优惠上限"));
      }
      if (type != 4) {
        if (isNaN(value)) return callback(new Error("请输入数字值"));
        if (type == 1 && unit == 2 && +value >= +threshold)
          return callback(new Error("减现金数不能超过优惠门槛"));
        if (type == 3 && unit == 1 && +value > +threshold)
          return callback(new Error("优惠件数不能超过优惠门槛"));
      }
      if (index) {
        const prevDiscounts =
          +this.addEditForm.activityRuleList[index - 1].discounts;
        if (type == 1 && +value <= (prevDiscounts ? prevDiscounts : 0))
          return callback(new Error("请输入大于上一个层级的减现金数字值"));
        if (type == 2 && +value > (prevDiscounts ? prevDiscounts : 0))
          return callback(new Error("请输入小于等于上一个层级的折扣数字值"));
        if (type == 3 && +value <= (prevDiscounts ? prevDiscounts : 0))
          return callback(new Error("请输入大于上一个层级的件数数字值"));
      }
      return callback();
    },
    checkUpLimit(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入优惠上限最高可减"));
      }
      return callback();
    },
    async checkExist() {
      if (!(this.addEditForm.warehouseId && this.dialogTitle == "新增")) return;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/activity/checkActivity",
        {
          params: {
            warehouseId: this.addEditForm.warehouseId,
          },
        }
      );
      if (res.data.errcode == 0) {
        if (res.data.data) {
          this.$confirm("当前列表已有此活动，请前往该活动进行编辑", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.dialogFormVisible = false;
              this.handleEdit({ id: res.data.data });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
              this.dialogFormVisible = false;
            });
        }
      } else {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
      }
    },
    toggleSelection(discounts) {
      const selection = discounts ? discounts.split(",") : [];
      this.$refs.activityGoods.clearSelection();
      this.activityGoodsList.forEach((row) => {
        if (selection.includes(row.id))
          this.$refs.activityGoods.toggleRowSelection(row, true);
      });
    },
    addDiscounts(index) {
      this.discountsIndex = index;
      this.toggleDrawer();
    },
    closeDrawer() {
      this.discounts = [];
      this.discountsText = "";
      this.toggleDrawer();
    },
    toggleDrawer() {
      if (!this.addEditForm.warehouseId) {
        this.$message({
          message: "请先选择活动仓库",
          type: "warning",
        });
        return;
      }
      if (!this.drawer) this.getActivityGoodsList();
      this.drawer = !this.drawer;
      this.$set(
        this.addEditForm.activityRuleList[this.discountsIndex],
        "discounts",
        this.discounts.map((discount) => discount.id).join(",")
      );
      this.$set(
        this.addEditForm.activityRuleList[this.discountsIndex],
        "discountsText",
        this.discounts.map((goods) => goods.name).join(",")
      );
    },
    onActivityRuleTypeChange(label) {
      if (label == 4) {
        this.$set(this.addEditForm, "activityRuleList", [
          Object.assign({}, this.defaultActivityRuleList, {
            type: label,
          }),
        ]);
      } else {
        this.addEditForm.activityRuleList.forEach((rule, index) => {
          this.$set(this.addEditForm.activityRuleList[index], "type", label);
          this.$set(
            this.addEditForm.activityRuleList[index],
            "discounts",
            label == 4 ? "" : "0"
          );
        });
      }
    },
    onActivityTypeChange(label) {
      if (label == 5) {
        this.$set(this.addEditForm, "unit", "2");
        this.$set(this.addEditForm, "activityRuleList", [
          Object.assign({}, this.defaultActivityRuleList, {
            unit: "2",
          }),
        ]);
      } else {
        this.$set(this.addEditForm, "unit", "1");
        this.$set(this.addEditForm, "activityRuleList", [
          Object.assign({}, this.defaultActivityRuleList, {
            unit: "1",
          }),
        ]);
      }
    },
    onAddActivityGoods(goodsIds) {
      let ids = [];
      goodsIds.forEach(item=>{
        ids.push(item.goodsId)
      })
      this.$set(this.addEditForm, "activityGoodsFormList", goodsIds);
      this.$set(this.addEditForm, "goodsIds", ids.join(','));

    },
    onAddActivityGoodsGift({ goodsId, activityGiftsGoodsFormList }) {
      this.$set(
        this.addEditForm,
        "activityGoodsFormList",
        this.addEditForm.activityGoodsFormList.map((item) => {
          if (item.goodsId == goodsId)
            item.activityGiftsGoodsFormList = activityGiftsGoodsFormList;
          return item;
        })
      );
    },
    addActivityRuleList() {
      if (
        this.addEditForm.activityRuleList &&
        this.addEditForm.activityRuleList.length >= 5
      )
        return;
      this.$set(this.addEditForm, "activityRuleList", [
        ...this.addEditForm.activityRuleList,
        Object.assign({}, this.defaultActivityRuleList, {
          level: this.addEditForm.activityRuleList.length + 1,
          type:
            this.addEditForm.activityRuleList &&
            this.addEditForm.activityRuleList.length
              ? this.addEditForm.activityRuleList[0].type
              : "1",
        }),
      ]);
    },
    deleteActivityRuleList(row) {
      this.$set(this.addEditForm, "activityRuleList", [
        ...this.addEditForm.activityRuleList
          .filter((activityRule) => activityRule.level != row.level)
          .map((activityRule, index) => {
            activityRule.level = index + 1;
            return activityRule;
          }),
      ]);
    },
    async getActivityGoodsList() {
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsMall/getActivityGoodsList",
        {
          params: {
            type: "6",
            warehouseId: this.addEditForm.warehouseId,
          },
        }
      );
      if (res.data.errcode == 0) {
        this.activityGoodsList = res.data.data;
        this.$nextTick(() => {
          this.toggleSelection(
            this.addEditForm.activityRuleList[this.discountsIndex].discounts
          );
        });
      } else {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
      }
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/activity", {
          params: Object.assign(
            {
              current: _this.pagesData.currentPage,
              size: _this.pagesData.currentRows,
            },
            _this.searchForm
          ),
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val, key) {
      this[key] = [...val];
    },
    // 新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = Object.assign({}, this.defaultAddEditForm);
      this.addActivityRuleList();
      this.detailsShow = false;
    },
    // 修改
    async handleEdit(row) {
      console.log(row)
      this.activeId = row.id
      let that = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/activity/details",
        {
          params: { id: row.id },
        }
      );
      if (res.data.errcode == 0) {
        this.formatDetail(res.data.data);
        that.detailsShow = false;
        that.dialogTitle = "编辑";
        that.dialogFormVisible = true;
      }
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.detailsShow = false;
    },
    routerActivityGoods(activityId) {
      sessionStorage.setItem("activeTab", "57-60");
      this.changeMenu("57-60");
      this.$router.push({
        path: "/active/active_goods",
        query: { activityId },
      });
    },
    // 新增/编辑提交
    addEditSubmit(formName, toActivityGoods = false) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const addEditForm = JSON.parse(JSON.stringify(_this.addEditForm));
          if (
            !(
              addEditForm.activityRuleList &&
              addEditForm.activityRuleList.length
            )
          ) {
            _this.$message({
              message: "请填写优惠内容",
              type: "success",
            });
            return;
          }
          const data = Object.assign({}, addEditForm, {
            startTime: moment(addEditForm.time[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            endTime: moment(addEditForm.time[1]).format("YYYY-MM-DD HH:mm:ss"),
            type:
              addEditForm.type == 5
                ? addEditForm.type
                : addEditForm.activityRuleList[0].type,
            activityRuleList: addEditForm.activityRuleList.map(
              (activityRule) => {
                activityRule.unit = addEditForm.unit;
                activityRule.upLimit =
                  activityRule.upLimitType == "2" ? activityRule.upLimit : "0";
                delete activityRule.upLimitType;
                return activityRule;
              }
            ),
          });
          if (data.type == 5) data.activityRuleList[0].type = 5;
          delete data.time;
          delete data.goodsMallDTOList;
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/activity/add";
          } else {
            url = "/activity/edit";
          }
          console.log(data)
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.dialogFormVisible = false;
              if (toActivityGoods) {
                this.routerActivityGoods(!isNaN(res.data) ? res.data : data.id);
              } else {
                _this.listEvent();
              }
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此活动?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/activity/delete", {
              params: {
                ids: _this.ids.map((id) => id.id).join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    formatDetail(data) {
      const addEditForm = Object.assign({}, data, {
        status: data.status.toString(),
        type: data.type == "5" ? "5" : "0",
        time: [new Date(data.startTime), new Date(data.endTime)],
        activityRuleList: data.activityRuleList.map((activityRule) => {
          activityRule.type =
            data.type == "5" ? "1" : activityRule.type.toString();
          if (
            activityRule.goodsMallDTOList &&
            activityRule.goodsMallDTOList.length
          )
            activityRule.discountsText = activityRule.goodsMallDTOList
              .map((goods) => goods.name)
              .join(",");
          activityRule.upLimitType = activityRule.upLimit ? "2" : "1";
          return activityRule;
        }),
        unit:
          data.activityRuleList && data.activityRuleList.length
            ? data.activityRuleList[0].unit.toString()
            : "",
        activityGoodsFormList: data.goodsMallDTOList.map((goods) => ({
          goodsId: goods.id,
          activityGiftsGoodsFormList: goods.activityGiftsGoodsDTOList
            ? goods.activityGiftsGoodsDTOList.map((gift) => ({
                goodsName: gift.name,
                goodsNum: gift.goodsNum,
                giftsGoodsId: gift.id,
              }))
            : [],
        })),
      });
      this.addEditForm = addEditForm;
    },
    // 详情
    async handleDetails(row) {
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/activity/details",
        {
          params: { id: row.id },
        }
      );
      if (res.data.errcode == 0) {
        this.formatDetail(res.data.data);
        this.detailsShow = true;
        this.dialogTitle = "详情";
        this.dialogFormVisible = true;
      }
    },
    // 启用
    handleUp(row) {
      let _this = this;
      this.$confirm(
        `是否确认${row.status == 1 ? "禁用" : "发布"}此活动?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.get(
            this.$axios.defaults.basePath + "/activity/toIssue",
            {
              params: { id: row.id, status: row.status == 1 ? 0 : 1 },
            }
          );
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.titleBox {
  text-align: left;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.titleBox p {
  font-size: 18px;
  padding-left: 15px;
  line-height: 24px;
  border-left: 4px solid #409eff;
}

.type-radio {
  width: 100%;
  margin-bottom: 10px;
}

.type-mar {
  margin: 0 5px;
}

.type-width {
  width: 160px;
}

.type-width-small {
  width: 100px;
}

.type-up-limit {
  margin-left: 24px;
}

.type-up-limit > div:nth-of-type(1) {
  margin: 12px 0;
}

.block {
  display: block;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}

.demo-drawer__footer {
  display: flex;
  justify-content: space-between;
  margin: 32px auto;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
</style>
