<template>
  <div class="goods">
    <div class="pageMain">
      <div class="titleBox clearfix">
        <p class="float_lf">活动商品列表</p>
      </div>
      <!-- 检索 -->
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="产品名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">产品列表</p>
        <div
          v-if="!detailsShow"
          class="tableTopBtn clearfix"
          style="padding: 15px"
        >
          <el-button
            size="mini"
            type="primary"
            @click="handleAdd"
            v-if="addButton == '1'"
            class="el-icon-plus"
          >
            添加</el-button
          >
          <el-button
            size="mini"
            type="danger"
            :disabled="delGoodsList.length == 0"
            @click="handleDel"
            v-if="delButton == '1'"
            class="el-icon-delete"
            >删除</el-button
          >
        </div>
        <template>
          <el-table
            ref="multipleTable"
            :data="formatTableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="
              (val) => handleSelectionChange(val, 'delGoodsList')
            "
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="standardImg" label="产品规格图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.standardImg">
                  <el-image
                    style="width: 60px"
                    :src="scope.row.standardImg"
                    :preview-src-list="scope.row.standardImg.split(',')"
                  >
                  </el-image>
                </div>
                <img
                  v-else
                  src="../assets/img/no_img.png"
                  style="width: 60px"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="产品名称"
              min-width="140"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="describe"
              label="产品描述"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="standard" label="产品规格"></el-table-column>
            <el-table-column prop="num" label="库存"></el-table-column>
            <el-table-column prop="purchasing" label="进货价"></el-table-column>
            <el-table-column prop="price" label="原价"></el-table-column>
            <el-table-column
              prop="salePrice"
              label="乡兴供货价"
            ></el-table-column>
            <el-table-column prop="isTopCarriage" label="状态" min-width="80">
              <template slot-scope="scope">
                <p v-if="scope.row.isTopCarriage == 0" style="color: red">
                  未上架
                </p>
                <p
                  v-else-if="scope.row.isTopCarriage == 1"
                  style="color: #67c23a"
                >
                  已上架
                </p>
                <p v-else style="color: red">已下架</p>
              </template>
            </el-table-column>
            <el-table-column align="center" width="300" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="toggleDrawer(scope.row)"
                  v-if="searchButton == '1' && type == '4'"
                  >{{ detailsShow ? "查看赠品" : "选择赠品" }}</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleUp(scope.row, scope.$index)"
                  v-if="upDownButton == '1' && !detailsShow"
                  :disabled="scope.row.isTopCarriage == 1"
                  >上架</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDown(scope.row, scope.$index)"
                  v-if="upDownButton == 1 && !detailsShow"
                  :disabled="scope.row.isTopCarriage != 1"
                  >下架</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <el-dialog
      title="添加活动商品"
      :visible.sync="dialogFormVisible"
      width="65%"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="addEditCancel"
    >
      <el-table
        ref="activityGoods"
        :data="activityGoodsList"
        stripe
        style="width: 100%"
        @selection-change="(val) => handleSelectionChange(val, 'goodsList')"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="standardImg" label="产品规格图片">
          <template slot-scope="scope">
            <div class="demo-image__preview" v-if="scope.row.standardImg">
              <el-image
                style="width: 60px"
                :src="scope.row.standardImg"
                :preview-src-list="scope.row.standardImg.split(',')"
              >
              </el-image>
            </div>
            <img
              v-else
              src="../assets/img/no_img.png"
              style="width: 60px"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="产品名称"
          min-width="140"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="describe"
          label="产品描述"
          width="130px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="standard" label="产品规格"></el-table-column>
        <el-table-column prop="num" label="库存"></el-table-column>
        <el-table-column prop="purchasing" label="进货价"></el-table-column>
        <el-table-column prop="price" label="原价"></el-table-column>
        <el-table-column prop="salePrice" label="乡兴供货价"></el-table-column>
        <el-table-column prop="isTopCarriage" label="状态" min-width="80">
          <template slot-scope="scope">
            <p v-if="scope.row.isTopCarriage == 0" style="color: red">未上架</p>
            <p v-else-if="scope.row.isTopCarriage == 1" style="color: #67c23a">
              已上架
            </p>
            <p v-else style="color: red">已下架</p>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogForm">取 消</el-button>
        <el-button type="primary" @click="saveActivityGoods">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title="赠品管理"
      ref="drawer"
      :visible.sync="drawer"
      size="70%"
      :append-to-body="true"
    >
      <div style="padding: 32px">
        <el-form :model="activityGoodsGiftList" :rules="rules" ref="ruleForm">
          <el-table
            ref="activityGoodsGift"
            :data="activityGoodsGiftList"
            stripe
            style="width: 100%"
            @selection-change="(val) => handleSelectionChange(val, 'discounts')"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="icons" label="赠品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image
                    style="width: 60px"
                    :src="scope.row.icons"
                    :preview-src-list="scope.row.imgs.split(',')"
                  >
                  </el-image>
                </div>
                <img
                  v-else
                  src="../assets/img/no_img.png"
                  style="width: 60px"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="赠品名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="describe"
              label="赠品描述"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="color" label="颜色"> </el-table-column>
            <el-table-column prop="categoryName" label="类型">
            </el-table-column>
            <el-table-column prop="standard" label="规格" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="model" label="型号"> </el-table-column>
            <el-table-column prop="unit" label="单位"> </el-table-column>
            <el-table-column prop="mfrs" label="制造商"> </el-table-column>
            <el-table-column prop="enabled" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
                <p v-else style="color: #67c23a">启用</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="goodsNum" label="赠品数量" width="200">
              <template slot-scope="scope">
                <el-form-item
                  label="赠品数量"
                  :prop="'[' + scope.$index + '].goodsNum'"
                >
                  <el-input-number
                    v-model="scope.row.goodsNum"
                    :min="1"
                    size="small"
                    :disabled="detailsShow"
                  ></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div class="demo-drawer__footer">
          <el-button @click="closeDrawer">取 消</el-button>
          <el-button
            v-if="!detailsShow"
            type="primary"
            @click="confirmDrawer('ruleForm')"
            >选择完成</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "activeGoods",
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    detailsShow: {
      type: Boolean,
      default: () => false,
    },
    dialogTitle: {
      type: String,
      default: () => "新增",
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      searchForm: {
        name: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 产品类型
      goodsType: [],
      // 产品单位
      goodsUnit: [],
      // 产品状态
      goodsStatus: [
        {
          label: "未上架",
          id: "0",
        },
        {
          label: "已上架",
          id: "1",
        },
      ],
      activeName: "",
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 下拉产品
      selectGoods: [],
      warehouseList: [],
      // 产品购买列表
      buysListData: [],
      // 已下单人数
      buyNum: "",
      // 下单量
      orderNum: "",
      submitBtnLoad: false,
      // 起订量是否可修改
      minimumchange: false,
      // 产品规格数组
      goodsStandard: [
        {
          standard: "",
          purchasing: "",
          price: "",
          salePrice: "",
          num: "",
          minimum: "",
          limit: "",
          pointNum: "",
          point: "",
          standardImg: [],
        },
      ],
      standardId: "",
      activityGoodsList: [],
      goodsList: [],
      delGoodsList: [],
      drawer: false,
      activityGoodsGiftList: [],
      discounts: [],
    };
  },
  computed: {
    activityId() {
      return this.detail.id;
    },
    warehouseId() {
      return this.detail.warehouseId;
    },
    type() {
      return this.detail.activityRuleList[0].type;
    },
    formatTableData() {
      const reg = new RegExp(this.searchForm.name);
      return this.tableData.filter((data) => reg.test(data.name));
    },
    rules() {
      let data = {};
      this.activityGoodsGiftList.forEach((el, index) => {
        data = Object.assign({}, data, {
          ["[" + index + "].goodsNum"]: [
            { required: true, message: "请输入赠品数量", trigger: "blur" },
          ],
        });
      });
      return data;
    },
  },
  created() {
    this.tableData = this.detail.goodsMallDTOList || [];
    // this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      // if (item.authority == "goods_mall_update") {
      //   this.updateButton = "1";
      // } else if (item.authority == "goods_mall_add") {
      //   this.addButton = "1";
      // } else if (item.authority == "goods_mall_delete") {
      //   this.delButton = "1";
      // } else if (item.authority == "goods_mall_query") {
      //   this.searchButton = "1";
      // } else if (item.authority == "goods_mall_upDown") {
      //   this.upDownButton = "1";
      // }
      this.updateButton = "1";
      this.addButton = "1";
      this.delButton = "1";
      this.searchButton = "1";
      this.upDownButton = "1";
    });
  },
  methods: {
    toggleSelection(discounts = "", formEl, key) {
      const table = this.$refs[formEl];
      const selection =
        typeof discounts == "string"
          ? discounts.split(",")
          : discounts.map((discount) => discount.id);
      table.clearSelection();
      this[key].forEach((row) => {
        if (selection.includes(row.id)) table.toggleRowSelection(row, true);
      });
    },
    closeDrawer() {
      this.discounts = [];
      this.drawer = false;
    },
    toggleDrawer({ id } = {}) {
      if (!this.warehouseId) {
        this.$message({
          message: "请先选择活动仓库",
          type: "warning",
        });
        return;
      }
      if (id) this.goodsId = id;
      if (!this.drawer) this.getSelectGoodsList();
      this.drawer = !this.drawer;
      this.discounts = [];
    },
    confirmDrawer(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("addActivityGoodsGift", {
            goodsId: this.goodsId,
            activityGiftsGoodsFormList: this.discounts.map((discount) => ({
              goodsName: discount.name,
              goodsNum: discount.goodsNum,
              giftsGoodsId: discount.id,
            })),
          });
          this.discounts = [];
          this.drawer = false;
        }
      });
    },
    handleSelectionChange(val, key) {
      this[key] = val;
    },
    closeDialogForm() {
      this.dialogFormVisible = false;
      this.goodsList = [];
    },
    async saveActivityGoods() {
      if (!this.goodsList.length) {
        this.$message({
          message: "请选择要添加的活动商品",
          type: "warning",
        });
        return;
      }
      this.tableData = this.goodsList;
      this.pagesData.total = this.tableData.length;
      const goodsIds = this.tableData.map((goods) => {
        const activityGoodsForm = this.detail.activityGoodsFormList.find(
          (gift) => gift.goodsId == goods.id
        );
        return {
          goodsId: goods.id,
          activityGiftsGoodsFormList:
            activityGoodsForm && activityGoodsForm.activityGiftsGoodsFormList
              ? activityGoodsForm.activityGiftsGoodsFormList
              : [],
        };
      });
      this.$emit("addActivityGoods", goodsIds);
      this.dialogFormVisible = false;
    },
    async getActivityGoodsList(key = "activityGoodsList", type = "5") {
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsMall/getActivityGoodsList",
        {
          params: {
            type,
            warehouseId: this.warehouseId,
          },
        }
      );
      if (res.data.errcode == 0) {
        this[key] = res.data.data;
        this.$nextTick(() => {
          this.toggleSelection(this.tableData, "activityGoods", key);
        });
      } else {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
      }
    },
    async getSelectGoodsList() {
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/giftsGoods/selectGoodsList",
        {
          params: {},
        }
      );
      if (res.data.errcode == 0) {
        const activityGiftsGoodsForm = this.detail.activityGoodsFormList.find(
          (good) => good.goodsId == this.goodsId
        );
        this.activityGoodsGiftList = res.data.data.map((item) => {
          if (item.imgs) {
            item.icons = item.imgs.split(",")[0];
          }
          item.goodsNum = 1;
          if (
            activityGiftsGoodsForm &&
            activityGiftsGoodsForm.activityGiftsGoodsFormList
          ) {
            const gift = activityGiftsGoodsForm.activityGiftsGoodsFormList.find(
              (gift) => gift.giftsGoodsId == item.id
            );
            if (gift) item.goodsNum = gift.goodsNum;
          }
          return item;
        });
        if (
          activityGiftsGoodsForm &&
          activityGiftsGoodsForm.activityGiftsGoodsFormList
        )
          this.$nextTick(() => {
            this.toggleSelection(
              activityGiftsGoodsForm.activityGiftsGoodsFormList.map((gift) => ({
                id: gift.giftsGoodsId,
              })),
              "activityGoodsGift",
              "activityGoodsGiftList"
            );
          });
      } else {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
      }
    },
    // 请求列表
    async listEvent() {
      if (!this.activityId) return;
      this.loading = true;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/activity/activityGoodsList",
        {
          params: Object.assign(
            {
              current: this.pagesData.currentPage,
              size: this.pagesData.currentRows,
              activityId: this.activityId,
            },
            this.searchForm
          ),
        }
      );
      this.loading = false;
      if (res.data.errcode == 0) {
        this.tableData = res.data.data.records;
        this.pagesData.total = res.data.data.total;
      } else {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
      }
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      //   this.listEvent();
    },

    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      //   this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 新增
    handleAdd() {
      if (!this.warehouseId) {
        this.$message({
          message: "请先选择活动仓库",
          type: "warning",
        });
        return;
      }
      this.dialogFormVisible = true;
      this.getActivityGoodsList();
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
    },
    // 批量删除
    handleDel() {
      let _this = this;
      return new Promise((resolve, reject) => {
        this.$confirm("是否确认删除此产品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const delGoods = _this.delGoodsList.map((goods) => goods.id);
            _this.tableData = _this.tableData.filter(
              (data) => delGoods.indexOf(data.id) == -1
            );
            _this.$emit(
              "addActivityGoods",
              this.detail.activityGoodsFormList.filter(
                (goods) => delGoods.indexOf(goods.goodsId) == -1
              )
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
            reject();
          });
      });
    },
    // 上架
    handleUp(row, index) {
      let _this = this;
      const { warehouseId } = this.detail;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/activity/upDown", {
          params: {
            goodsId: row.id,
            isTopCarriage: 1,
            activityId:_this.activityId,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.$set(_this.tableData[index], "isTopCarriage", 1);
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 下架
    handleDown(row, index) {
      let _this = this;
      const { warehouseId } = this.detail;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/activity/upDown", {
          params: {
            goodsId: row.id,
            isTopCarriage: 2,
            activityId: _this.activityId,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.$set(_this.tableData[index], "isTopCarriage", 2);
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>
<style>
/* .rowStyle >>>.el-table--enable-row-transition .el-table__body td.el-table__cell{
  background-color: #f5f5f5;
} */
.el-table__row.expanded + tr,
.el-table__row.expanded + tr td,
.el-table__row.expanded + tr td .el-table,
.el-table__row.expanded + tr td .el-table tr,
.el-table__row.expanded + tr td .el-table tr th {
  background-color: #f5f5f5 !important;
}
</style>
<style scoped>
::v-deep .pageMain {
  min-height: auto;
  padding: 0;
}
.goods_buys {
  padding: 0 30px;
  margin-top: 15px;
  box-sizing: border-box;
}
.goods_buys .buys_title {
  line-height: 1.5rem;
  font-size: 1.125rem;
  color: #303133;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.buys_total p {
  margin-right: 20px;
}
.titleBox {
  text-align: left;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.titleBox p {
  font-size: 18px;
  padding-left: 15px;
  line-height: 24px;
  border-left: 4px solid #409eff;
}
.standard_box .standard_list {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px dashed #333;
}
.standard_box > div:first-child .standard_list {
  border-top: none !important;
}

.demo-drawer__footer {
  margin: 32px auto;
}
</style>
